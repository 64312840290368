<template>
    <div class="settings">
        <div class="title">
            <div class="sub-title">账号设置</div>
        </div>
        <el-card class="box-card" style="min-height:100%">
            <el-form ref="settingsFormRef" :model="settingsForm" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="账号名: ">
                            <div class="tips">{{settingsForm.userName}}</div>
                        </el-form-item>
                        <el-form-item label="修改logo: ">
                            <upload-img @changeurl="changeLegalIdCardPre" :picurl="settingsForm.logo"></upload-img>
                        </el-form-item>
                        <el-form-item label="新密码: ">
                            <el-input v-model="settingsForm.password" type="password" placeholder="无需修改密码时，请不要填写~"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码: ">
                            <el-input v-model="settingsForm.againPassword" type="password" placeholder="无需修改密码时，请不要填写~"></el-input>
                        </el-form-item>
                        <div class="submit flex">
                            <el-button class="button3 active" :loading="loading" @click="editCompanyInfo">完成修改</el-button>
                            <!-- <div class="forget" @click="routeToResetPassword">忘记旧密码？</div> -->
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UploadImg from '../components/UploadImg'
import companyRequest from '../../../api/company'
export default {
    mounted() {
        this.getMemberInfo()
    },
    components: {
        UploadImg
    },
    data() {
        return {
            loading: false,
            settingsForm: {
                userName: '',
                password: null,
                againPassword: null,
                logo: ''
            }
        }
    },
    methods: {
        //获取企业信息
        getMemberInfo() {
            companyRequest.getMemberInfo().then((res) => {
                if (res.code === 200) {
                    this.settingsForm.userName = res.data.userName
                    this.settingsForm.logo = res.data.logo
                }
            })
        },
         //获取营业执照图片地址
        changeLegalIdCardPre(url) {
           this.settingsForm.logo = url
            this.$message.success('图片上传成功') 
        },
        routeToResetPassword() {
            this.$router.push({
                path: '/company/resetPassword'
            })
        },
        //修改企业logo、密码
        editCompanyInfo() {
            if (this.settingsForm.password !== null && !/^[0-9a-zA-Z]{6,12}$/g.test(this.settingsForm.password)) {
                this.$message.error('密码只能由6-12位字母或数字组成');
                return
            }
            if (this.settingsForm.againPassword !== null && !/^[0-9a-zA-Z]{6,12}$/g.test(this.settingsForm.againPassword)) {
                this.$message.error('确认密码长度不小于6位');
                return
            }
            if (this.settingsForm.password != this.settingsForm.againPassword) {
                this.$message.error('2次密码输入不一致');
                return
            }
            this.loading = true
            companyRequest.editCompanyInfo(this.settingsForm).then((res) => {
                if (res.code === 200 && !this.settingsForm.password && !this.settingsForm.againPassword) {
                    this.loading = false
                    this.$message.success('修改成功')
                } else {
                    this.loading = false
                    this.$message.success('密码修改成功，请重新登录')
                    // window.localStorage.setItem('userInfo', JSON.stringify({}))
                    // window.localStorage.setItem('token', '')
                    // window.localStorage.setItem('authInfoStatus', null)
                    setTimeout(() => {
                        this.$router.push({
                            path: '/newLogin'
                        }).catch(() => {})
                    }, 1000)
                }
            }).catch((err) => {
                this.$message.error(err)
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
    .settings{
        height: 100%;
        .tips{
            font-weight: 600;
            color: #000;
            font-size: 16px;
        }
        .submit{
            align-items: center;
            margin-left: 100px;
            .button3{
                width: 190px;
            }
            .forget{
                margin-left: 12px;
                font-size: 12px;
                color: #595757;
                cursor: pointer;
            }
        }
    }
</style>